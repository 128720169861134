<template>
  <div>
    <v-row
      class="page-break"
      no-gutters>
      <v-col cols="12">
        <p class="field-label">
          <b> Order Detail </b>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Sketch ID: </b>
          <span>{{ sketchId }}</span>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Produt ID: </b>
          <span>{{ productId }} </span>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Product Factory ID: </b>
          <span>{{ productFactoryId }} </span>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Factory: </b>
          <span>{{ factory }} </span>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Order Date: </b>
          <span>{{ orderDate }} </span>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Target Date: </b>
          <span>{{ targetDate }} </span>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Designer: </b>
          <span>{{ designer }} </span>
        </p>
      </v-col>
      <v-col cols="3">
        <p class="field-label">
          <b> Merchandiser: </b>
          <span>{{ merchandiser }} </span>
        </p>
      </v-col>
      <v-col
        cols="12"
        class="pb-3">
        <v-divider />
      </v-col>
    </v-row>
    <v-row class="page-break">
      <v-col cols="12">
        <p><b> Amount & Quantity </b></p>
        <v-row>
          <v-col
            v-for="color in colors"
            :key="`color-${color}`"
            cols="auto">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="field-label text-center">
                    Size
                  </th>
                  <th class="field-label text-center">
                    Color
                  </th>
                  <th class="field-label text-center">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sku, skuIndex) in filterSkusByColor(color)"
                  :key="`productSku-${colors}-${skuIndex}`">
                  <td class="text-center text-uppercase">
                    {{ sku.size }}
                  </td>
                  <td class="text-center text-uppercase">
                    {{ sku.color }}
                  </td>
                  <td class="text-center">
                    {{ sku.quantity }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <p><b>Size Details</b></p>
        <v-row>
          <v-col cols="auto">
            <v-simple-table
              v-if="hasSizeDetail"
              dense>
              <thead>
                <tr>
                  <th>
                    <p class="label mb-0 text-center">
                      Detail / Size
                    </p>
                  </th>
                  <th
                    v-for="(size) in sizeDetail"
                    :key="`head-sizeDetail-${size.size}`">
                    <p class="label mb-0 text-uppercase text-center">
                      {{ size.size }}
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(key, keyIndex) in optionKeys"
                  :key="`mainkey-${keyIndex}`">
                  <td class="text-center">
                    {{ key }}
                  </td>
                  <td
                    v-for="(size, sizeIndex) in sizeDetail"
                    :key="`sizeDetail-${sizeIndex}-${keyIndex}`"
                    class="text-center">
                    {{ size.options[keyIndex].value }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <p
              v-else
              class="text-center">
              ไม่มี Size Details
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="page-break-after"></div>
    <v-row class="page-break fitting-image-row">
      <v-col cols="12">
        <p class="field-label mb-0 text-center">
          <b>
            Fitting Image:
          </b>
        </p>
      </v-col>
      <v-col
        v-for="(fittingImage, fittingImageIndex) in fittingImages"
        :key="`fitting-images(${fittingImageIndex})`"
        :class="{
          'fitting-lastrow': isLastRowFittingImage(fittingImageIndex)
        }"
        class="fitting-image img-preview-col"
        cols="6">
        <div class="img-preview-wrap">
          <img
            class="image-preview export-preview-img"
            :src="fittingImage">
        </div>
      </v-col>
      <v-col cols="12">
        <p>
          <b>Note:</b>
        </p>
        <p style="white-space: pre-line;">
          {{ fittingNote }}
        </p>
      </v-col>
    </v-row>
    <div class="page-break-after"></div>
    <v-row class="material-row">
      <v-col cols="12">
        <p class="field-label mb-0 text-center">
          <b>
            Material Detail:
          </b>
        </p>
      </v-col>
    </v-row>
    <template v-for="(material, materialIndex) in materials">
      <v-row
        :key="`material-row(${materialIndex})`"
        :class="{'page-break ': materialIndex !== 0}"
        class="material-image-row">
        <v-col
          v-for="(materialImage, materialImageIndex) in material.images"
          :key="`material-images(${materialImageIndex})`"
          class="material-image img-preview-col"
          :class="{
            'last-material-image': isLastMaterialImage(materialIndex, materialImageIndex)
          }"
          cols="6">
          <div class="img-preview-wrap">
            <img
              class="image-preview export-preview-img"
              :src="materialImage">
          </div>
        </v-col>
        <v-col cols="6">
          <p>
            <b>Note:</b>
          </p>
          <p>
            {{ material.note || '' }}
          </p>
        </v-col>
        <v-col cols="3">
          <p>
            <b>Quantity:</b>
          </p>
          <p>
            {{ material.quantity || '' }}
          </p>
        </v-col>
        <v-col cols="3">
          <p>
            <b>Unit Price:</b>
          </p>
          <p>
            {{ material.unitPrice || '' }}
          </p>
        </v-col>
      </v-row>
      <div
        :key="`page-break-after-${materialIndex}`"
        class="page-break-after" />
    </template>
  </div>
</template>

<script>
import { prototypeData } from '@/assets/js/DefaultPrototypeData'

export default {
  props: {
    prototype: {
      type: Object,
      default: () => prototypeData
    }
  },
  computed: {
    colors () {
      return [...new Set(this.productSkus.map((sku) => sku.color))]
    },
    sketchId () {
      return this.prototype?.sketch?.sketchId || ''
    },
    productId () {
      return this.prototype?.order?.orderId || ''
    },
    productFactoryId () {
      return this.prototype?.order?.productFactoryId || ''
    },
    factory () {
      return this.prototype?.order?.factory?.name || this.prototype?.sketch?.factory?.name || ''
    },
    orderDate () {
      return this.prototype?.order?.orderDate ? this.$dayjs(this.prototype.order.orderDate).format('DD/MM/YYYY') : ''
    },
    targetDate () {
      return this.prototype?.order?.targetDate ? this.$dayjs(this.prototype.order.targetDate).format('DD/MM/YYYY') : ''
    },
    designer () {
      return this.prototype?.sketch?.designer?.name || ''
    },
    merchandiser () {
      return this.prototype?.sketch?.merchandiser?.name || ''
    },
    productSkus () {
      return this.prototype?.order?.productSkus || []
    },
    sizeDetail () {
      return this.prototype?.order?.sizeDetail || []
    },
    fittingImages () {
      return this.prototype?.order?.fitting?.images || []
    },
    isOneRowFittingImage () {
      return this.fittingImages.length === 1 || this.fittingImages.length === 2
    },
    fittingNote () {
      return this.prototype?.order?.fitting?.note || ''
    },
    optionKeys () {
      const { sizeDetail } = this
      if (!Array.isArray(sizeDetail) || sizeDetail.length === 0 || !Array.isArray(sizeDetail[0].options) || sizeDetail[0].options.length === 0) {
        return []
      }
      return sizeDetail[0].options.map((option) => option.key)
    },
    hasSizeDetail () {
      return this.optionKeys.length > 0 && this.productSkus.length > 0
    },
    materials () {
      return this.prototype?.order?.materials || []
    },
    lastIndexFittingImage () {
      const imgLength = this.fittingImages.length
      const lastIndex = [imgLength - 1]
      if (imgLength % 2 === 0) {
        lastIndex.push(imgLength - 2)
      }
      return lastIndex
    },
    modMaterial () {
      const arrMaterial = []
      for (let i = 0; i < this.materials.length; i++) {
        const qtyMaterial = this.materials[i]?.images?.length || 0
        arrMaterial.push((qtyMaterial - (qtyMaterial % 4)) - 1)
      }
      return arrMaterial
    }
  },
  methods: {
    filterSkusByColor (color) {
      return this.productSkus.filter((sku) => sku.color === color)
    },
    isLastRowFittingImage (index) {
      return this.lastIndexFittingImage[0] === index || this.lastIndexFittingImage[1] === index
    },
    isLastMaterialImage (mIndex, imgIndex) {
      return imgIndex > this.modMaterial[mIndex]
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 8.0pt;
  font-size: 11pt;
  line-height: 107%;
}
tr {
  > td:first-child,
  > th:first-child {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
}
.img-preview-col {
  .img-preview-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      margin: 0 auto;
      max-height: 100%;
      padding: 12px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.first-material-image-row.material-image-row .material-image img {
  max-height: calc(35vh - 60px);
}
.material-image-row .material-image img {
  max-height: calc(40vh - 60px);
}
.fitting-image-row {
  .fitting-image {
    height: 95vh;
  }
  .fitting-image.fitting-lastrow {
    height: 80vh;
  }
}
.material-image-row {
  .material-image {
    height: calc(40vh - 60px);
  }
}
</style>
